import { serializable } from 'legend-builder/data/serialization'
import { action, computed, observable, toJS } from 'mobx'
import { validateObject, vif } from 'utils/validx-validators'
import { builtinTypes as T } from '@taxfyle/ryno'
import MutatorEditor from 'legend-builder/effects/MutatorEditor'
import RynoEditor from '../../../ryno-editor/RynoEditor'
import { func } from 'validx'

export default class InventoryItemMutatorEditor extends MutatorEditor {
  type = 'InventoryItem'

  /**
   * Whether we're using an expression or a simple value.
   * @type {'Expr' | 'Value'}
   */
  @observable
  mode = 'Value'

  /**
   * The operator. Can be `Inc`, `Dec`, `Mult`, or `Replace`.
   * @type {'Inc' | 'Dec' | 'Mult' | 'Replace'}
   */
  @observable
  @serializable
  op = 'Inc'

  @observable
  quantity

  /**
   * Which properties to modify.
   *
   * @type {{quantity: boolean, courtesyMultiplier: boolean}}
   */
  @observable.shallow
  @serializable(toJS)
  properties = {
    quantity: true,
    courtesyMultiplier: false,
  }

  rules = {
    targets: [
      validateObject(
        'Please select the service item element(s) you wish to change.'
      ),
    ],
    expr: [
      vif(() => this.mode === 'Expr', [
        func((ctx) => ctx.value.analyze(), 'There are formula errors.'),
      ]),
    ],
  }

  constructor(attrs, opts) {
    super(null, opts)
    this.expr = new RynoEditor({
      required: () => this.mode === 'Expr',
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => T.number,
    })
    this.set(attrs, opts)
    // Validate the symbol ref when the symbol changes
    this.targets.bubbleErrorsTo(this, 'targets')
  }

  @computed
  get symbolTable() {
    const table = this.effect.symbolTable.scope('mutator')
    table.setIdentifierSymbolsFromObject({
      Value: [T.number, { constant: true }],
    })
    return table
  }

  isCompatibleTarget({ type }, valueType) {
    return type === 'InventoryItem'
  }

  @action.bound
  setOperator(op) {
    this.op = op
  }

  @action.bound
  setExprMode() {
    this.mode = 'Expr'
  }

  @action.bound
  setValueMode() {
    this.mode = 'Value'
  }

  @action.bound
  setProperties(properties = {}) {
    this.properties = {
      ...this.properties,
      ...properties,
    }
  }

  parse(...args) {
    const { quantity, properties = {}, ...attrs } = super.parse(...args)

    if (typeof quantity === 'string') {
      this.expr.setValue(quantity)
      this.setExprMode()
    } else {
      this.quantity = quantity
      this.setValueMode()
    }

    this.properties = {
      quantity: !!properties.quantity,
      courtesyMultiplier: !!properties.courtesyMultiplier,
    }

    return attrs
  }

  toJSON() {
    const serialized = super.toJSON()
    if (this.mode === 'Expr') {
      serialized.quantity = this.expr.toJSON()
    } else {
      serialized.quantity = this.quantity || 0
    }

    return serialized
  }
}
