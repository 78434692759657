import { computed, observable } from 'mobx'
import { snapshotable, serializable } from 'legend-builder/data/serialization'
import RynoEditor from 'legend-builder/ryno-editor/RynoEditor'
import { validateObject } from 'utils/validx-validators'
import { func } from 'validx'
import MutatorEditor from '../../MutatorEditor'
import { equalsType } from '@taxfyle/ryno'

/**
 * Replace Mutator.
 */
@snapshotable
export default class ReplaceMutatorEditor extends MutatorEditor {
  type = 'Replace'

  /**
   * The formula
   */
  @observable
  @serializable
  expr

  rules = {
    targets: [
      validateObject('Please select the variable(s) you wish to change.'),
    ],
    expr: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
  }

  /**
   * Constructor.
   * @param {*} attrs
   * @param {*} opts
   */
  constructor(attrs, opts) {
    super(null, opts)
    this.expr = new RynoEditor({
      required: true,
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => this.targets.valueType,
    })
    this.set(attrs, opts)
    // Validate the targets list when there are changes to the inner connectors.
    this.targets.bubbleErrorsTo(this, 'target')
  }

  /**
   * Symbol table including `Value`.
   */
  @computed
  get symbolTable() {
    const table = this.effect.symbolTable.scope('mutator')
    table.setIdentifierSymbolsFromObject({
      Value: [this.targets.valueType, { constant: true }],
    })
    return table
  }

  /**
   * Used for filtering allowed variables.
   */
  isCompatibleTarget({ type }, valueType, connector) {
    if (type !== 'Variable') {
      return false
    }

    // If the targets list is empty, or we have only a single connector,
    // then any type is allowed,
    if (this.targets.count <= 1) {
      return true
    }

    // Short-circuit if evaluating the first connector
    if (this.targets.connectors[0] === connector) {
      return true
    }

    // Otherwise, the value type must be the same as the
    // first connector in the list.
    return equalsType(this.targets.connectors[0].valueType, valueType)
  }

  /**
   * Parses from raw JS.
   */
  parse({ expr, ...rest }) {
    this.expr.setValue(expr)
    return super.parse(rest)
  }
}
