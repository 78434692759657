import identity from 'lodash/identity'
import { action, computed, observable, reaction } from 'mobx'
import { validateElements } from '../../utils/validx-validators'
import { snapshotable } from '../data/serialization'
import { validatable } from '../mixins/validation'
import { addGenericErrorForProperty, receiveErrors } from '../data/serverErrors'
import { typeBuilder as T } from '@taxfyle/ryno'

/**
 * A list of symbol connectors.
 */
@snapshotable
@validatable()
export default class SymbolConnectorList {
  /**
   * List of connectors.
   * @type {SymbolConnector[]}
   */
  @observable connectors = []

  rules = {
    connectors: [
      validateElements(),
      () => (this.required ? this.connectors.length > 0 || '' : true),
    ],
  }

  /**
   * Initializes the connector.
   *
   * @param {LegendEditorViewStore} legendEditor the legend editor instance
   * @param {Array<Function>} predicates an array of predicates used to filter the entity list
   * @param {any} context used to ask the editor what entities are available
   * @param {boolean | undefined} required whether at least one symbol is required
   */
  constructor({ legendEditor, predicates, context, required }) {
    this.legendEditor = legendEditor
    this.required = required
    this.context = context
    this.predicates = predicates || [identity]

    // Make sure there's always at least one connector.
    if (this.required) {
      this.add()
    }
  }

  /**
   * Determines if the editor controls are disabled.
   */
  @computed
  get editorDisabled() {
    return this.legendEditor.disabled
  }

  /**
   * Value type represented by the targets.
   *
   * @returns {RynoType|*}
   */
  @computed
  get valueType() {
    // The value type is the first connector's value type.
    return this.connectors.length > 0 ? this.connectors[0].valueType : T.nothing
  }

  /**
   * How many connectors do we have in the list.
   *
   * @returns {number}
   */
  @computed
  get count() {
    return this.connectors.length
  }

  /**
   * Hydrates the list of symbols.
   *
   * @param {string[] | undefined} symbols
   * @returns {SymbolConnectorList}
   */
  hydrate(symbols = []) {
    this.connectors.length = 0
    for (const target of symbols) {
      const connector = this.add()
      connector.hydrate(target)
    }

    // Make sure there's always at least one connector.
    if (this.connectors.length === 0 && this.required) {
      this.add()
    }

    return this
  }

  /**
   * Adds another connector.
   */
  @action.bound
  add() {
    const connector = this.legendEditor.createSymbolConnector({
      required: true,
      legendEditor: this.legendEditor,
      context: this.context,
      predicates: [
        ...this.predicates,
        (entityDescriptor) => {
          // Filter out entities that are already selected by other
          // connectors in this list.
          const isAlreadySelected = this.connectors.some((c) => {
            if (c === connector) {
              return false // current connector doesn't count
            }

            return c.symbolId === entityDescriptor.entity.id
          })
          return !isAlreadySelected
        },
      ],
    })
    // Don't trigger validation bubbling when adding the connector initially.
    this._silenceNextValidationBubbling = true
    this.connectors.push(connector)
    return connector
  }

  /**
   * Removes the connector from the list.
   * @param connector
   */
  @observable
  remove(connector) {
    // Don't trigger validation bubbling when removing a connector.
    this._silenceNextValidationBubbling = true
    this.connectors.remove(connector)
  }

  /**
   * Delivers errors to each mutator.
   *
   * @param {*} descriptor
   */
  receiveErrors(descriptor = {}) {
    addGenericErrorForProperty(
      this,
      descriptor,
      'targets',
      'There are invalid targets.'
    )
    receiveErrors(this.connectors, descriptor.inner)
  }

  /**
   * Serializes the symbols to JSON.
   */
  toJSON() {
    return this.connectors.map((c) => c.toJSON()).filter(Boolean)
  }

  /**
   * Focuses the last connector in the list.
   */
  focus() {
    if (this.connectors.length) {
      this.connectors[this.connectors.length - 1].focus()
    }
  }

  /**
   * Sets up error bubbling.
   *
   * @param {*} target
   * @param {*} field
   */
  bubbleErrorsTo(target, field) {
    // Validate field on target when any of the symbol props of the
    // first connector changes, since it's where we get the value type from.
    return reaction(
      () => {
        if (this.connectors.length === 0) {
          return
        }

        const connector = this.connectors[0]
        return (
          connector.symbol && [connector.symbol.id, connector.symbol.valueType]
        )
      },
      () => {
        if (this._silenceNextValidationBubbling) {
          this._silenceNextValidationBubbling = false
          return
        }
        target.validate([field])
      },
      {
        fireImmediately: false,
      }
    )
  }
}
