import { validateObject } from 'utils/validx-validators'
import MutatorEditor from 'legend-builder/effects/MutatorEditor'

export default class ClearInventoryItemQuantityMutatorEditor extends MutatorEditor {
  type = 'ClearInventoryItemQuantity'

  rules = {
    targets: [
      validateObject(
        'Please select the service item element(s) you wish to clear.'
      ),
    ],
  }

  constructor(attrs, opts) {
    super(null, opts)
    this.set(attrs, opts)
    this.targets.bubbleErrorsTo(this, 'targets')
  }

  isCompatibleTarget({ type }, valueType) {
    return type === 'InventoryItem'
  }
}
