/*
  There are certain things that are incompatible between the old legend format
  and the one that the current builder is catering for.

  We need to map between them for the time being.
*/
import flatMap from 'lodash/flatMap'
import flattenDeep from 'lodash/flattenDeep'
import { startsWithValidCharacter } from '../../utils/idUtil'

const IdPrefixes = {
  Question: 'Q',
  Milestone: 'M',
  Task: 'T',
}

export function replaceInventoryItems(legend, itemMap) {
  legend.questions.forEach((q) => walk(q, (x) => swapItems(x, itemMap)))

  function walk(question, fn) {
    fn(question)
    switch (question.type) {
      case 'Path':
        question.questions.forEach((q) => walk(q, fn))
    }
  }

  function swapItems(question, itemMap) {
    if (question.inventory_item_id === itemMap.previousItemId) {
      question.inventory_item_id = itemMap.newItemId
    }
  }
}

/**
 * Maps from the server format to the UI format (mostly triggerWhens and routing rules).
 * @param {*} legend
 */
export function fromLegacyLegend(legend) {
  if (!legend.stories) {
    return legend
  }
  const fixer = createIdFixer()
  const allQuestions = flattenDeep(
    legend.stories.map((s) => s.questions.map(extractQuestions))
  )
  const ctx = {
    allQuestions,
    ids: fixer,
  }

  return {
    ...legend,
    infoLines: legend.infoLines?.map((line) => ({
      ...line,
      triggerWhen: fromLegacyRules(line.triggerWhen, ctx),
    })),
    milestones: legend.milestones?.map((m) => fromLegacyMilestone(m, ctx)),
    routing: legend.routing
      ? {
          ...legend.routing,
          requirements: fromLegacyRules(legend.routing.requirements, ctx),
        }
      : legend.routing,
    questions: legend.stories[0]
      ? legend.stories[0].questions.map((q) => fromLegacyQuestion(q, ctx))
      : [],
  }
}

/**
 * Maps from a legacy milestone, fixing the IDs.
 *
 * @param milestone
 * @param ctx
 */
function fromLegacyMilestone(milestone, ctx) {
  return {
    ...milestone,
    id: ctx.ids.fix(IdPrefixes.Milestone, milestone.id),
    triggerWhen: fromLegacyRules(milestone.triggerWhen, ctx),
    tasks: milestone.tasks?.map((t) => fromLegacyTask(t, ctx)),
  }
}

/**
 * Maps from a legacy task, fixing the IDs.
 * @param task
 * @param ctx
 * @returns {{}}
 */
function fromLegacyTask(task, ctx) {
  return {
    ...task,
    id: ctx.ids.fix(IdPrefixes.Task, task.id),
    triggerWhen: fromLegacyRules(task.triggerWhen, ctx),
  }
}

/**
 * Maps a legacy question to the UI format.
 *
 * @param {*} question
 * @param {*} ctx
 */
function fromLegacyQuestion(question, ctx) {
  const result = {
    id: ctx.ids.fix('Q', question.id),
    label: question.label,
    question: question.question,
    description: question.description,
    triggerWhen: fromLegacyRules(question.triggerWhen, ctx),
  }

  switch (question.type) {
    case 'BOOL':
      result.type = 'Bool'
      result.yes = fromLegacyEffect(question.yes, ctx)
      result.no = fromLegacyEffect(question.no, ctx)
      break
    case 'SELECT':
      result.type = 'Select'
      result.multi = question.multi
      result.options = question.options?.map((o) => fromLegacyEffect(o, ctx))
      result.effect = fromLegacyEffect(question.effect, ctx)
      break
    case 'TEXT':
      result.type = 'Text'
      break
    case 'INVENTORY_ITEM':
      result.type = 'InventoryItem'
      result.inventory_item_id = question.inventory_item_id
      result.quantity = question.quantity
      break
    case 'PATH':
      result.type = 'Path'
      result.questions = question.questions.map((q) =>
        fromLegacyQuestion(q, ctx)
      )
      break
    case 'BREAKER':
      result.type = 'Breaker'
      break
    case 'NUMERIC':
      result.type = 'Numeric'
      result.format = question.format
      result.effect = fromLegacyEffect(question.effect, ctx)
      break
    case 'DATE_TIME':
      result.type = 'DateTime'
      result.collect_time = question.collect_time
      result.disallow_past = question.disallow_past
      result.disallow_future = question.disallow_future
      break
    case 'DOCUMENTS':
      result.type = 'Documents'
      result.options = question.options?.map((o) => fromLegacyEffect(o, ctx))
      break
    case 'EFFECT': {
      result.type = 'Effect'
      const mappedEffect = fromLegacyEffect(question, ctx)
      result.price = mappedEffect.price
      result.output = mappedEffect.output
      result.removes = mappedEffect.removes
      result.mutators = mappedEffect.mutators
      break
    }
    case 'VARIABLE':
      result.type = 'Variable'
      result.valueType = question.valueType
      result.expr = question.expr
      break
  }

  return result
}

/**
 * Extracts questions into a flat list.
 *
 * @param {*} question
 */
export function extractQuestions(question) {
  if (!question) {
    return []
  }

  if (question.type === 'PATH') {
    return flatMap(question.questions || [], extractQuestions)
  }
  return [question]
}

/**
 * Maps an array of rules (triggerWhen)
 * @param rules
 * @param ctx
 */
function fromLegacyRules(rules, ctx) {
  if (!rules) {
    return rules
  }

  return rules.map((rule) => fromLegacyRule(rule, ctx))
}

/**
 * Maps a rule.
 *
 * @param rule
 * @param ctx
 */
function fromLegacyRule(rule, ctx) {
  if (!rule) {
    return rule
  }

  switch (rule.type) {
    case 'BinaryOperation': {
      return {
        ...rule,
        target: ctx.ids.resolve(IdPrefixes.Question, rule.target),
      }
    }
    case 'Conjunction': {
      return {
        ...rule,
        rules: fromLegacyRules(rule.rules, ctx),
      }
    }
    case 'If': {
      return {
        ...rule,
        conditionRule: fromLegacyRule(rule.conditionRule, ctx),
        thenRule: fromLegacyRule(rule.thenRule, ctx),
        elseRule: fromLegacyRule(rule.elseRule, ctx),
      }
    }
    default: {
      return rule
    }
  }
}

/**
 * Maps an effect.
 *
 * @param effect
 * @param ctx
 * @returns {{mutators: (*|(*&{target: *}))[]}|*}
 */
function fromLegacyEffect(effect, ctx) {
  if (!effect?.mutators) {
    return effect
  }

  return {
    ...effect,
    mutators: effect.mutators.map((m) => fromLegacyMutator(m, ctx)),
  }
}

/**
 * Maps a mutator.
 *
 * @param mutator
 * @param ctx
 */
function fromLegacyMutator(mutator, ctx) {
  if (!mutator) {
    return mutator
  }

  return {
    ...mutator,
    targets: mutator.targets.map((t) =>
      ctx.ids.resolve(IdPrefixes.Question, t)
    ),
  }
}

/**
 * Converts from the UI format to the server format.
 *
 * @param questions
 * @param {*} state
 */
export function toLegacyLegend({ questions, ...state }) {
  if (!questions) {
    return state
  }

  questions = questions.map(toLegacyQuestion)
  return {
    ...state,
    stories: [
      {
        id: 'ROOT',
        first: true,
        questions,
      },
    ],
  }
}

/**
 * Converts to legacy question format.
 * @param {*} q
 */
function toLegacyQuestion(q) {
  switch (q.type) {
    case 'Bool':
      return {
        ...q,
        type: 'BOOL',
      }
    case 'Select':
      return {
        ...q,
        type: 'SELECT',
      }
    case 'Breaker':
      return {
        ...q,
        type: 'BREAKER',
      }
    case 'Effect':
      return {
        ...q,
        type: 'EFFECT',
      }
    case 'Text':
      return {
        ...q,
        type: 'TEXT',
      }
    case 'Numeric':
      return {
        ...q,
        type: 'NUMERIC',
      }
    case 'DateTime':
      return {
        ...q,
        type: 'DATE_TIME',
      }
    case 'Documents':
      return {
        ...q,
        type: 'DOCUMENTS',
      }
    case 'Variable':
      return {
        ...q,
        type: 'VARIABLE',
      }
    case 'InventoryItem':
      return {
        ...q,
        type: 'INVENTORY_ITEM',
      }
    case 'Path':
      return {
        ...q,
        questions: q.questions.map(toLegacyQuestion),
        type: 'PATH',
      }
  }
  console.warn(`Unknown question type: ${q.type}`)
  return q
}

/**
 * Creates an ID fixer.
 * It caches usages so we can make globally unique IDs.
 *
 * In Engine v1.14, a change was made to the analyzer and the
 * runtime that requires any entity that has an ID to be unique.
 * Prior to 1.14, Milestones and Tasks had IDs but were not
 * required to be unique or to satisfy the stricter ID format requirements
 * since they don't provide any value for referencing yet.
 * To be proactive about this, all entities within a Legend that has an ID
 * must now be globally unique, and must satisfy the entity ID requirements.
 * By fixing IDs here, it won't wreak (potential) havoc on existing deployed Legend
 * versions, and any future publishes of the loaded Legend will have the fixed
 * IDs.
 */
function createIdFixer() {
  const used = new Set()
  return {
    fix,
    resolve,
  }

  /**
   * Resolves an ID by adding a prefix but does not reserve a new one
   * Used for referencing IDs.
   * @param prefix
   * @param id
   * @returns {string}
   */
  function resolve(prefix, id) {
    if (!id) {
      return id
    }

    // TODO: Remove this once we disable the job total hack.
    if (id === '__job_total') {
      return id
    }

    if (!startsWithValidCharacter(id)) {
      id = `${prefix}_${id}`
    }
    return id
  }

  /**
   * Fixes an ID by making it globally unique.
   * Used for declaring IDs.
   *
   * @param prefix
   * @param id
   * @returns {string}
   */
  function fix(prefix, id) {
    id = resolve(prefix, id)
    if (!id) {
      return id
    }

    let count = 1
    let next = id
    do {
      const usage = used.has(next)
      if (!usage) {
        used.add(next)
        return next
      }
      count++
      next = `${id}_${count}`
    } while (true)
  }
}
