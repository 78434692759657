import { serializable } from 'legend-builder/data/serialization'
import { observable } from 'mobx'
import { validateObject } from 'utils/validx-validators'
import MutatorEditor from 'legend-builder/effects/MutatorEditor'

export default class CopyInventoryItemMutatorEditor extends MutatorEditor {
  type = 'CopyInventoryItem'

  /**
   * The inventory item to copy from.
   *
   * @type {SymbolConnector}
   */
  @observable
  @serializable
  source

  rules = {
    targets: [
      validateObject(
        'Please select the service item element(s) you wish to copy to.'
      ),
    ],
    source: [
      validateObject(
        'Please select the service item element you wish to copy from.'
      ),
    ],
  }

  constructor(attrs, opts) {
    super(null, opts)

    this.source = this.legendEditor.createSymbolConnector({
      context: this.effect.context,
      required: true,
      predicates: [({ type }) => type === 'InventoryItem'],
    })

    this.set(attrs, opts)
    this.targets.bubbleErrorsTo(this, 'targets')
    this.source.bubbleErrorsTo(this, 'source')
  }

  focus() {
    this.source.focus()
  }

  /**
   * Used to check whether the target item is a valid option.
   */
  isCompatibleTarget({ type, entity }) {
    return type === 'InventoryItem' && this.source.symbolId !== entity.id
  }

  parse({ targets, source, ...attrs }) {
    this.targets.hydrate(targets)
    this.source.hydrate(source)
    return attrs
  }
}
