import { observable, action, computed } from 'mobx'
import { snapshotable, serializable } from 'legend-builder/data/serialization'
import { Types } from 'legend-builder/data/Type'
import RynoEditor from 'legend-builder/ryno-editor/RynoEditor'
import { isNumberType } from '@taxfyle/ryno'
import { validateObject } from 'utils/validx-validators'
import { func } from 'validx'
import MutatorEditor from '../../MutatorEditor'

/**
 * Vary Mutator.
 */
@snapshotable
export default class VaryMutatorEditor extends MutatorEditor {
  type = 'Vary'

  /**
   * The operator. Can be `Inc` or `Dec`.
   */
  @observable
  @serializable
  op = 'Inc'

  /**
   * The formula
   */
  @observable
  @serializable
  expr

  rules = {
    targets: [
      validateObject('Please select the variable(s) you wish to change.'),
    ],
    expr: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
  }

  /**
   * Constructor.
   * @param {*} attrs
   * @param {*} opts
   */
  constructor(attrs, opts) {
    super(null, opts)
    this.expr = new RynoEditor({
      required: true,
      getSymbolTable: () => this.symbolTable,
      getNeededType: () => Types.number,
    })
    this.set(attrs, opts)
    // Validate the targets list when there are changes to the inner connectors.
    this.targets.bubbleErrorsTo(this, 'targets')
  }

  @computed
  get symbolTable() {
    const table = this.effect.symbolTable.scope('mutator')
    table.setIdentifierSymbolsFromObject({
      Value: [Types.number, { constant: true }],
    })
    return table
  }

  isCompatibleTarget({ type }, valueType) {
    return type === 'Variable' && isNumberType(valueType)
  }

  /**
   * Sets the operator.
   * @param {*} op
   */
  @action.bound
  setOperator(op) {
    this.op = op
  }

  /**
   * Parses from raw JS.
   */
  parse({ expr, ...rest }) {
    this.expr.setValue(expr)
    return super.parse(rest)
  }
}
