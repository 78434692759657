import { observable, action, computed } from 'mobx'
import { snapshotable, serializable } from 'legend-builder/data/serialization'
import { Types } from 'legend-builder/data/Type'
import RynoEditor from 'legend-builder/ryno-editor/RynoEditor'
import { equalsType, isListType } from '@taxfyle/ryno'
import { validateObject } from 'utils/validx-validators'
import { func } from 'validx'
import MutatorEditor from '../../MutatorEditor'

/**
 * List Mutator.
 */
@snapshotable
export default class ListMutatorEditor extends MutatorEditor {
  type = 'List'

  /**
   * The operator. Can be `Add` or `Remove`.
   */
  @observable
  @serializable
  op = 'Add'

  /**
   * The formula
   */
  @observable
  @serializable
  expr

  /**
   * Whether we are adding one or many items.
   */
  @observable
  @serializable
  multi = false

  rules = {
    targets: [
      (ctx) => {
        return true
      },
      validateObject('Please select the variable(s) you wish to change.'),
    ],
    expr: [func((ctx) => ctx.value.analyze(), 'There are formula errors.')],
  }

  /**
   * Constructor.
   * @param {*} attrs
   * @param {*} opts
   */
  constructor(attrs, opts) {
    super(null, opts)
    this.expr = new RynoEditor({
      required: true,
      getSymbolTable: () => this.symbolTable,
      getNeededType: () =>
        this.multi
          ? this.targets.valueType
          : (isListType(this.targets.valueType) &&
              this.targets.valueType.elementType) ||
            Types.list(Types.nothing),
    })
    this.set(attrs, opts)
    // Validate the symbol ref when the symbol changes
    this.targets.bubbleErrorsTo(this, 'targets')
  }

  /**
   * Symbol table including `Value`.
   */
  @computed
  get symbolTable() {
    const table = this.effect.symbolTable.scope('mutator')
    table.setIdentifierSymbolsFromObject({
      Value: [this.targets.valueType, { constant: true }],
    })
    return table
  }

  isCompatibleTarget({ type }, valueType, connector) {
    if (type !== 'Variable') {
      return false
    }

    // Only list types are allowed.
    if (!isListType(valueType)) {
      return false
    }

    // If the targets list is empty, or we have only a single connector,
    // then any list is allowed.
    if (this.targets.count <= 1) {
      return true
    }

    // Short-circuit if evaluating the first connector
    if (this.targets.connectors[0] === connector) {
      return true
    }

    // Otherwise, the value type must be the same as the
    // first connector in the list.
    return equalsType(this.targets.connectors[0].valueType, valueType)
  }

  /**
   * Sets the operator.
   * @param {*} op
   */
  @action.bound
  setOperator(op) {
    this.op = op
  }

  /**
   * Sets the multi field.
   * @param {*} multi
   */
  @action.bound
  setMulti(multi) {
    this.multi = multi
  }

  /**
   * Parses from raw JS.
   */
  parse({ expr, ...rest }) {
    this.expr.setValue(expr)
    return super.parse(rest)
  }
}
